<template>
  <div class="front-offices-list" @scroll.passive="onScroll($event.srcElement, $refs.fixedElement.$el)">
    <screen-header
      :title="
        $tc('frontOffices.list.title', frontOfficesPagination.nbResults, {
          brand: currentClient.name,
          count: frontOfficesPagination.nbResults,
        })
      "
      :breadcrumbs="breadcrumbs"
      :is-loading="isLoading && !isScrolling"
    />
    <screen-container :full-height="true">
      <screen-grid :full-height="true">
        <screen-card :full-height="true">
          <template v-slot:body>
            <ui-data-search
              :element-fixed="elementFixed"
              :scroll-direction="scrollDirection"
              :is-mobile="isMobile"
              :is-tablet="isTablet"
              :is-loading="isLoading"
              :is-scrolling="isScrolling"
              :creation-roles="[userRoles.superAdmin]"
              view="frontOffices"
              ref="fixedElement"
              :withAddButton="true"
              @add="addLocator"
              @search="onSearch"
            />
            <div class="front-offices-list__list">
              <ui-data-list
                :items="frontOfficesMobile"
                :is-loading="isLoading"
                :is-scrolling="isScrolling"
                :no-more-data="noMoreData"
                @click="goToFrontOffice({ row: { id: $event.id } })"
              >
                <template v-slot:skeleton>
                  <div class="front-offices-list__list__content">
                    <skeleton-line height="18px" width="80%" />
                    <skeleton-line height="15px" width="50%" margin-top="3px" />
                  </div>
                  <skeleton-icon margin-left="16px" />
                </template>
                <template v-slot:item="slotProps">
                  <img :src="slotProps.item.logo" class="front-offices-list__list__logo" v-if="slotProps.item.logo" />
                  <div class="front-offices-list__list__content">
                    <div class="front-offices-list__list__content__name">
                      {{ slotProps.item.name }}
                    </div>
                    <address class="front-offices-list__list__content__url">
                      {{ slotProps.item.url }}
                    </address>
                  </div>
                  <span
                    class="front-offices-list__list__status backoffice-icons"
                    :class="[`front-offices-list__list__status--${slotProps.item.status}`]"
                  >
                    <template v-if="slotProps.item.status === '1'"> success </template>
                    <template v-else>error</template>
                  </span>
                </template>
              </ui-data-list>
            </div>
            <div class="front-offices-list__table">
              <ui-data-table
                :columns="columns"
                :rows="frontOffices"
                :total-rows="totalRows"
                :is-loading="isLoading || isUpdating"
                :pagination="frontOfficesPagination"
                :defaultApiCallPerPage="100"
                @loadItems="loadFrontOffices($event, false, true)"
                @onCellClick="goToFrontOffice"
              >
                <template slot="data-table-row" slot-scope="props">
                  <router-link
                    class="front-offices-list__table__link"
                    :to="{ name: 'FrontOffice', params: { id: props.row['id'] } }"
                  />
                  <span v-if="props.column.field === 'name'">
                    <div class="front-offices-list__table__block">
                      <img
                        :src="props.row['logo']"
                        class="front-offices-list__table__block__logo"
                        v-if="props.row['logo']"
                      />
                      <div class="front-offices-list__table__block__content">
                        <div class="front-offices-list__table__block__content__name">
                          {{ props.row['name'] }}
                        </div>
                        <address class="front-offices-list__table__block__content__url">
                          {{ props.row['url'] }}
                        </address>
                      </div>
                    </div>
                  </span>
                  <span v-if="props.column.field === 'localeName'">
                    <div class="tw-flex tw-gap-2">
                      <v-img
                        v-if="getFlagSrc(props.row['localeCode'])"
                        :src="getFlagSrc(props.row['localeCode'])"
                        :key="props.row['localeCode']"
                        max-width="24"
                      />
                      {{ props.row['localeName'] }}
                    </div>
                  </span>
                  <span v-else-if="props.column.field === 'status'">
                    <span
                      class="front-offices-list__table__status backoffice-icons"
                      :class="[`front-offices-list__table__status--${props.row['status']}`]"
                    >
                      <template
                        v-if="
                          props.row['status'] === '1' || props.row['status'] === '10' || props.row['status'] === '20'
                        "
                      >
                        success
                      </template>
                      <template v-else>error</template>
                    </span>
                  </span>
                </template>
              </ui-data-table>
            </div>
          </template>
        </screen-card>
      </screen-grid>
    </screen-container>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import UiDataTable from '@/components/UI/DataTable.vue'
import UiDataList from '@/components/UI/DataList.vue'
import UiDataSearch from '@/components/UI/DataSearch.vue'
import ScreenHeader from '@/components/Screen/Header.vue'
import ScreenContainer from '@/components/Screen/Container.vue'
import ScreenGrid from '@/components/Screen/Grid.vue'
import ScreenCard from '@/components/Screen/Card.vue'
import SkeletonLine from '@/components/Skeleton/Line.vue'
import SkeletonIcon from '@/components/Skeleton/Icon.vue'
import { onScroll } from '@/mixins/scroll.mixin'
import { userRoles } from '@/config/permissions.config'

export default {
  name: 'FrontOfficesList',
  components: {
    UiDataTable,
    UiDataList,
    UiDataSearch,
    ScreenHeader,
    ScreenContainer,
    ScreenGrid,
    ScreenCard,
    SkeletonLine,
    SkeletonIcon,
  },
  mixins: [onScroll],
  data() {
    return {
      search: '',
      isLoading: false,
      isScrolling: false,
      isUpdating: false,
      noMoreData: false,
      totalRows: 0,
      userRoles,
    }
  },
  mounted() {
    this.loadFrontOffices(null, true)
  },
  watch: {
    canRefreshData: {
      handler() {
        if (this.canRefreshData && !this.isScrolling && !this.noMoreData) {
          this.isScrolling = true
          this.loadFrontOffices({ page: this.frontOfficesPagination.page + 1 })
        }
      },
    },
    frontOffices: {
      handler() {
        if (this.frontOffices.length === 1) {
          this.$router.push({ name: 'FrontOffice', params: { id: this.frontOffices[0].id } })
        }
      },
    },
  },
  computed: {
    ...mapState({
      frontOffices: state => state.frontoffice.frontOffices,
      frontOfficesMobile: state => state.frontoffice.frontOfficesMobile,
      frontOfficesPagination: state => state.frontoffice.frontOfficesPagination,
      currentClient: state => state.client.currentClient,
    }),
    noData() {
      return this.isLoading || this.noMoreData
    },
    breadcrumbs() {
      return [
        {
          label: this.$t('frontOffices.list.breadcrumb'),
          route: {
            name: 'FrontOffice',
          },
        },
      ]
    },
    columns() {
      return [
        {
          label: this.$t('frontOffices.list.column.location'),
          field: 'name',
          sortable: false,
        },
        {
          label: this.$t('frontOffices.list.column.language'),
          field: 'localeName',
          sortable: false,
        },
        {
          label: this.$t('frontOffices.list.column.status'),
          field: 'status',
        },
      ]
    },
  },
  methods: {
    ...mapActions({
      getFrontOffices: 'frontoffice/getFrontOffices',
    }),
    goToFrontOffice(e) {
      this.$router.push({ name: 'FrontOffice', params: { id: e.row.id } }).catch(() => {})
    },
    onSearch(value) {
      this.search = value
      this.loadFrontOffices(null, true, true)
    },
    loadFrontOffices(tableParams = null, resetFrontOffices = false, updating = false) {
      if (updating) {
        this.isUpdating = true
      } else {
        this.isLoading = true
      }
      this.getFrontOffices({ tableParams, resetFrontOffices, search: this.search })
        .then(() => {
          this.totalRows = this.frontOfficesPagination.nbResults
          this.isLoading = false
          this.isScrolling = false
          this.isUpdating = false
        })
        .catch(() => {
          this.noMoreData = true
          this.isLoading = false
          this.isScrolling = false
          this.isUpdating = false
        })
    },
    addLocator() {
      this.$router.push({ name: 'FrontOfficeCreate' })
    },

    getFlagSrc(country) {
      try {
        return require(`@/assets/img/flags/${country.toLowerCase()}.svg`)
      } catch {
        return null
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.front-offices-list {
  padding-bottom: $button-min-height + $gutter-mobile * 2;

  @media (min-width: $screen-sm) {
    padding-bottom: 0;
  }
  &__list {
    position: relative;
    margin-bottom: -16px;

    @media (min-width: $screen-sm) {
      margin: 0 (-$gutter-tablet) (-$gutter-tablet);
    }

    @media (min-width: $screen-md) {
      display: none;
    }
    &__logo {
      margin-right: $gutter-mobile;
      width: 36px;
      height: 36px;

      @media (min-width: $screen-sm) {
        margin-right: $gutter-tablet;
      }
    }
    &__content {
      flex: 1;
      &__name {
        font-weight: 600;
      }
      &__url {
        @include text-ellipsis(1, 12px);

        margin-top: 3px;
        font-size: $font-size-sm;
        font-style: normal;

        @media (min-width: $screen-sm) {
          @include text-ellipsis(1, 14px);

          margin-right: $gutter-tablet;
          font-size: $font-size-default;
        }
      }
    }
    &__status {
      margin-left: $gutter-mobile;
      color: map-get($generic-color-variants, 'error');
      &--1,
      &--10,
      &--20 {
        color: map-get($generic-color-variants, 'success');
      }
    }
  }
  &__table {
    display: none;

    @media (min-width: $screen-md) {
      display: block;
      height: 100%;
      overflow-y: auto;
    }
    &__link {
      @include full-link;
    }
    &__status {
      color: map-get($generic-color-variants, 'error');
      &--1,
      &--10,
      &--20 {
        color: map-get($generic-color-variants, 'success');
      }
    }
    &__block {
      display: flex;
      align-items: center;
      &__logo {
        margin-right: $gutter-mobile;
        width: 36px;
        height: 36px;

        @media (min-width: $screen-md) {
          margin-right: $gutter-tablet;
        }
      }
      &__content {
        display: flex;
        flex-direction: column;
        &__name {
          font-weight: 600;
        }
        &__url {
          @include text-ellipsis(1, 14px);

          margin-top: 3px;
          font-size: $font-size-default;
          font-style: normal;
        }
      }
      &__icon {
        border: 1px solid;
        border-radius: 50%;
        padding: 3px;
        color: map-get($generic-color-variants, 'influence');
        &:first-child {
          margin-right: 8px;
        }
        &--inactive {
          color: $generic-color-bg-disabled;
        }
      }
    }
  }
}
</style>
